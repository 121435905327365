.templatesDashboard-container {
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.templatesDashboard-row {
    &.createWorksheetSection {
        justify-content: center;
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        border-radius: 10px;
        background-image: url(../../../../assets/Dashboard/dashboardHeaderBackdrop.png);
        background-size: cover; /* Make the image cover the entire div */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Prevent image repetition */
    }

    &.templatesSection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
    }
}

.templatesDashboard-createWorksheetSection-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.templatesDashboard-createWorksheetSection-title {
    color: var(--text-black);
    text-align: center;
    margin-bottom: 0;

    /* Heading 1 */
    font-family: "Avenir Next";
    font-size: 28px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
}

.templatesDashboard-createWorksheetSection-description {
    width: 100%;

    color: var(--text-black);
    text-align: center;

    /* Paragraph 1 */
    font-family: "Avenir Next";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.templatesDashboard-createWorksheetSection-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.templatesDashboard-createWorksheetSection-button {
    display: flex;
    width: 250px;
    height: 40px;
    align-items: center;
    align-self: center;
    justify-content: center;
    gap: 10px;

    border-radius: 10px;
    border: 1px solid var(--Light-Purple, #8C52FF);
    background: linear-gradient(90deg, #8C52FF 28.5%, #A546D2 93.5%);
}

.templatesDashboard-createWorksheetSection-button-icon {
    width: 25px;
    height: 25px;
}

.templatesDashboard-createWorksheetSection-button-text {
    margin: 0;

    text-align: center;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: white;
}

/* Template Library Section */
.templatesDashboard-templatesSection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.templatesDashboard-templatesSection-heading {
    color: var(--text-black);

    /* Heading 2 */
    font-family: "Avenir Next";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.templatesDashboard-templatesSection-seeTemplates-container {
    display: flex;
    gap: 5px;
    width: 170px;
    height: 25px;
    align-self: center;
}

.templatesDashboard-templatesSection-seeTemplates-text {
    margin: 0;
    color: var(--primary-color);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.templatesDashboard-templatesSection-seeTemplates-icon {
    width: 22px;
    height: 21px;
    flex-shrink: 0;
}

/* Template Library Cards */
.templatesDashboard-templatesSection-cards {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.templatesDashboard-card {
    display: flex;
    width: 190px;
    height: 150px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
    border-radius: 10px;
    text-align: left;
}

.templatesDashboard-card-img {
    width: 55px;
    height: 55px;
    padding: 15px 65px;
    background-color: var(--secondary-color);
    border-radius: 5px;
    align-self: center;
}

.templatesDashboard-card-title {
    margin: 0;
    color: var(--text-black);
    text-align: left;

    /* Heading 2 */
    font-family: "Avenir Next";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.templatesDashboard-card-description {
    margin: 0;
    text-align: left;

    color: var(--text-grey);

    /* Paragraph 1 */
    font-family: "Avenir Next";
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

