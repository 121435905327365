
.createWorksheet-header-container {
    display: flex;
    justify-content: space-between;
}

.createWorksheet-header {
    color: var(--text-black);

    /* Heading 1 */
    font-family: "Avenir Next";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.createWorksheet-upload-container {
    display: flex;
    flex-direction: row;
}

.createWorksheet-configure-container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
}

.createWorksheet-configure-info {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
}

.createWorksheet-configure-info-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.createWorksheet-configure-info-header {
    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: flex;
    gap: 5px;
}

.createWorksheet-configure-info-textField {
    box-sizing: border-box;
    height: 100px;
    width: 100%;
    padding: 10px 10px;
    resize: none;

    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);

    /* Text Styling */
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    ::placeholder {
        color: var(--text-grey);
        font-family: "Avenir Next";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    
}

.createWorksheet-configure-info-textInput {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 10px;

    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);

    /* Text Styling */
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    ::placeholder {
        color: var(--text-grey);
        font-family: "Avenir Next";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    
}

.createWorksheet-configure-info-dropdown {
    display: flex;
    height: 42px;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);

    /* Text Styling */
    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.createWorksheet-submit-button {
    margin-top: 20px;

    display: flex;
    padding: 10px 30px;
    justify-content: center;
    align-self: flex-end;
    gap: 10px;

    border-radius: 5px;
    background: var(--primary-color);

    border-radius: 5px;
    border: 1px solid var(--Light-Purple, #8C52FF);
    background: linear-gradient(90deg, #8C52FF 28.5%, #A546D2 93.5%);

    &:hover {
        padding: 11px 31px;
    }

    color: var(--White, #FEFEFE);
    text-align: center;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.createWorksheet-configure-field-icon {
    width: 20px;
    height: 20px;
}

.input-invalid {
    border: 1px solid red;
}