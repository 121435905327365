.teacherProfile-container {
	display: flex;
	flex-direction: column;
	margin-left: 5px;
	margin-right: 5px;
	/* overflow-y: auto; */

	@media (max-width: 991px) {
		max-width: 100%;
	}
	margin-top: 25px;
}


.section {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	margin-bottom: 30px;
}

.sectionTitle {
	font-size: 24px;
    color: var(--text-black);
}

.sectionLink {
	font-weight: 900;
	color: var(--primary-color);
	font: bold;
	text-decoration: underline;
	font-size: 18px;
	justify-self: center;
}


.content {
	position: relative;
	z-index: 100; 
	padding: 20px;
	height: 100vh;
}

@media only screen and (max-device-width: 1366px) {
	.banner {
	  background-attachment: scroll;
	}
  } 

