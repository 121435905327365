.previewWorksheet-container {
    margin-top: 20px;
    /* height: 84.5vh; */
    /* width: 100%; */
    overflow-x: hidden;
    /* aspect-ratio: 6/9; */

    /* height: calc(width * 1.5); */

    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);
    overflow-y: scroll;
    height: 100%;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}

.previewWorksheet-outer-container {
    padding-bottom: 50px;
}
.previewWorksheet-loading-container {
    height: 70vh;
    justify-content: center;
}

.previewWorksheet-title {
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin: 0;
}

.previewWorksheet-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* padding-top: 5px; */
}

.previewWorksheet-pageCount {
    margin: 0;
}

.previewWorksheet-preview {
    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);
}

.previewWorksheet-footer {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.previewWorksheet-footer-button {
    color: var(--primary-color);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Dropdown Button */
.previewWorksheet-dropbtn {
    background-color: var(--primary-color);
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
}

.previewWorksheet-dropbtn#delete {
    background-color: tomato;
}

/* Dropdown button on hover & focus */
.previewWorksheet-dropbtn:hover, .previewWorksheet-dropbtn:focus {
    background-color: var(--primary-heavy-color);
}

/* Dropdown button on hover & focus */
.previewWorksheet-dropbtn#delete:hover, .previewWorksheet-dropbtn#delete:focus {
    background-color: darkred;
}