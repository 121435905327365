.errorScreen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 80vh;
}

.errorScreen-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorScreen-img {
    align-self: center;
    width: 500px;
}

.errorScreen-text {
    margin-top: 20px;
    text-align: center;
    font-size: 24px;
}