.siteHeader-container {
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	align-self: stretch;

}


.siteHeader-profile {
	width:	45px;
	height: 45px;
	padding: 5px;

	border-radius: 100px;
	background-color: var(--primary-color);
}
