.yourActivitiesDashboard-container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    &.empty {
        align-items: center;
    }
}

.yourActivitiesDashboard-grid {
    display: flex;
    /* width: 1190px; */
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px;
}

.yourActivitiesDashboard-noActivities-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.yourActivitiesDashboard-noActivities-img{
    width: 208px;
    height: 167px;
}

.yourActivitiesDashboard-noActivities-text {
    color: var(--text-grey);
    text-align: center;

    /* Heading 2 */
    font-family: "Avenir Next";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


