.dashboard-container {
	display: flex;
	flex-direction: column;
	margin-left: 5px;
	margin-right: 5px;
	/* overflow-y: auto; */

	@media (max-width: 991px) {
		max-width: 100%;
	}
}

