/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    padding-left: 10px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    position: absolute;
    background-color: var(--mid-light-grey);
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    z-index: 100;
    right: 0;
}

.dropdown-content#up {
    bottom: 100%;
}

.dropdown-content * {
    font-family: 'Avenir Next';
    color: black;
    padding: 12px 16px;
    font-size: 14px;
    text-decoration: none;
    display: block;
    width: 100%; 
    text-align: left;
}

.dropdown-content *:hover {
    background-color: #ddd;
    border-radius: 5px;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Change color of dropdown links on hover */
.previewWorksheetdropdown-content a:hover {
    background-color: #ddd;
    border-radius: 5px;
}