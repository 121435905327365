
.sliderSelector-container {
    text-align: center;
    width: 100%;
    min-width: 600px;
}

.sliderSelector-slider {
    width: 85%;
    margin: 10px 0;
    z-index: 2;
    accent-color: var(--primary-color);
}

.sliderSelector-labels {
    display: flex;
    justify-content: space-between;

    &.firstRow {
        margin-top: -5px; /* Adjust to position the labels closer to the slider */
    }
}

.sliderSelector-labels div {
    width: 16.6%; 
}

.sliderSelector-output {
    margin-top: 20px;
    font-size: 18px;
}

.sliderSelector-label-title {
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sliderSelector-label-desc {
    font-family: "Avenir Next";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
