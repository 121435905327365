.popupConfirm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure the overlay is on top of other content */
}

.popupConfirm {
    /* Layout */
    background-color: white;
    /* padding: 20px; */
    padding-bottom: 20px;
    border-radius: 8px;
    position: relative;
    width: 20%;
    box-sizing: border-box;
    /* height: 220px; */
    flex-shrink: 0;
    min-width: 400px;

    /* Style */
    border-radius: 10px;
    background: var(--White, #FEFEFE);
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.28);
}

.popupConfirm-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 0;
    color: var(--text-grey);
    
}

.popupConfirm-close-button-img {
    height: 30px;
    width: 30px;
}

.popupConfirm-header {
    background: var(--Light-Grey, #F6F5F4);
    object-fit: contain;
    height: 30px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 5px;
}

.popupConfirm-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.popupConfirm-description {
    margin: 10px;
    font-size: 20px;
    text-align: center;
}

.popupConfirm-buttonOptions {
    display: flex;
    gap: 30px;
}

.popupConfirm-button {
    background-color: var(--primary-color);
    color: white;
    padding: 5px 10px;
    font-size: 18px;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: var(--primary-heavy-color);
    }
}