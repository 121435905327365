.topStatisticsContainer {
    display: flex;
    justify-content: space-between; /* Distribute space between items */
}

.statContainer {
    flex: 1; /* Each box will take up equal space */
    margin: 0 10px; /* Space between boxes */
    padding: 20px; /* Inner padding for content within the boxes */
    text-align: center; /* Center the text inside the boxes */
    box-sizing: border-box; /* Include padding in width calculations */
    background-color: var(--light-grey); /* Background color for visibility */
    border-radius: 8px;
}


.statContainer:first-child {
    margin-left: 0; /* Remove margin from the first box */
}
  
.statContainer:last-child {
    margin-right: 0; /* Remove margin from the last box */
}