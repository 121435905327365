.loadingBar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 104vh;
    width: 100%;
    position: relative;
    overflow-y: hidden;
    margin-bottom: -40px;
}

.loadingBar-progress-bar {
    width: 80%;
    max-width: 400px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
}

.loadingBar-progress {
    width: 0;
    height: 100%;
    background-color: var(--primary-color);
}

.loadingBar-gif {
    position: absolute;  /* Stick the GIF to a specific spot */
    bottom: 0;        /* Align to the bottom of the screen */
    left: 50%;        /* Center horizontally */
    transform: translateX(-50%); /* Adjust position to center it exactly */
    z-index: 9999;    /* Ensure it stays on top of other elements */
    width: 50%;
    height: auto;
  }

@keyframes fill {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}