.basic-button {
	font-family: 'Avenir Next' !important;
	background-color: var(--primary-color);
    color: white;
    font-size: 16px;
    padding: 10px;
	padding-left: 15px;
	padding-right: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
	text-decoration: none !important;
}

.long-text {
    min-width: 500px !important;
}

.library-card {
    max-width: 1040px !important;
	min-width: 1040px !important;
}

.fixed-height {
	max-height: 280px !important;
}

.basic-button:hover {
	background-color: var(--primary-lighter-color);
}


.basic-button:active {
    background-color: var(--primary-heavy-color);
}

.basic-pop-up {
	border-radius: 10px !important;
    width: 150%;
}


.introjs-tooltiptext {
	font-family: 'Avenir Next' !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.introjs-tooltipbuttons {
	border-top: none !important;
	margin-bottom: 10px;
	margin-right: 12px;
}

.introjs-helperLayer {
	box-shadow: rgba(33, 33, 33, 0.54) 0px 0px 0px 5000px !important;
	border-radius: 10px !important;
}

.introjs-tooltip-title {
	display: none !important;
}

.introjs-prevbutton {
	display: none !important;
}