
.finishWorksheet-container {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;

    border-radius: 10px;
    background: var(--background-white-lighter);
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.15);
}

/* Styling for Preview Section*/
.finishWorksheet-preview-container {
    flex: 65%;
    padding: 40px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
    gap: 20px;

    background-color: var(--background-white);
    border-radius: 0px 10px 10px 0px;
}

.finishWorksheet-preview-worksheet {
    width: clamp(350px, 45%, 500px);
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.finishWorksheet-options-container {
    flex: 35%;
    padding: 30px;
    max-width: 200px;

    display: flex;
    flex-direction: column;

}

.finishWorksheet-options-worksheet-container {
    max-height: 75%;
    overflow-y: scroll;
}

.finishWorksheet-options-title {
    color: var(--primary-color);
    font-family: "Avenir Next";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.finishWorksheet-options-worksheet-title{
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.finishWorksheet-options-worksheet-description{
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 5;
}

.finishWorksheet-saveExit-button {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: center;
    margin-top: auto;

    border-radius: 5px;
    background: var(--primary-color);

    /* Text Styling */
    color: white;
    text-align: center;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
        background: var(--primary-heavy-color);
    }
}