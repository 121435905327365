:root {
	--primary-color: #6A49DB;
	--secondary-color: #ffce00;
  	--primary-lighter-color: #C199FF;
  	--secondary-lighter-color: #F6D074;
	--primary-muted-color: #cec2fd;
	--secondary-muted-color: #fff2bc;
	--primary-heavy-color: #593cb6;
	--secondary-heavy-color: #c9a300;
	--light-grey: #e7e6e4;
	--mid-light-grey: #CDCDCD;
	--text-grey: #5D5669;
	--text-black: #2F2F2F;
	--background-white: #f8f9fa;
	--background-white-lighter: #FEFEFE;
	--font-stack: -apple-system, "Avenir", "Helvetica Neue", Arial, sans-serif;
	--base-padding: 0rem;
}

body,
html {
	margin: 0;
	padding: 0;
	font-family: var(--font-stack);
	background-color: var(--background-white-lighter);
	color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	color: var(--primary-color);
}

a {
	color: var(--primary-color);
	text-decoration: none;
}
a:hover {
	text-decoration: underline;
}

button,
input[type="submit"] {
	background-color: transparent;
	color: white;
	border: none;
	padding: 0.5rem 1rem;
	border-radius: 0.25rem;
	cursor: pointer;
	font-family: var(--font-stack);
}
button:hover,
input[type="submit"]:hover {
	background-color: darken(var(--primary-color), 10%);
}

.base-container {
	display: flex;
	width: 100%;
	height: 100%;
	height: 100vh;
	/* max-width: 1200px; */
	margin: 0 auto;
	padding: 0 var(--base-padding);
	overflow-y: hidden;
}

.base-content-container {
	flex-basis: 80%;
	flex: 1;
	font-family: "Avenir Next";
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 40px;
	overflow-y: auto;
}

.ck-body-wrapper {
	margin-top: -30px;
	display: none;
}

@font-face {
	font-family: "Avenir Next";
	src: url("./assets/AvenirNext-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "SF Pro";
	src: url("./assets/SF-Pro-Display-Regular.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
  }
