/* HelpPage.css */
.help-container {
    /* width: 100%; */
    /* padding-top: 50px; */
    margin: 0 auto;
    /* padding-top: 100px; */
    padding: 100px;
    color: #333;
}
  
.help-title {
    text-align: center;
    margin-bottom: 50px;
    /* color: --var(--primary-color); */
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* width: 335px; */
    /* margin: 0; */
}

.help-section {
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
}

.help-section-title {
    font-size: 18px;
    margin-bottom: 10px;
    color: var(--primary-color);
    font-weight: 700;
}

.help-section-subtitle {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.help-section form {
    display: flex;
    flex-direction: column;
}

.help-restartTutorial-button {
    background-color: #4b4bda;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.help-restartTutorial-button:hover {
    background-color: #3a3aaf;
}

.help-section a {
    color: #4b4bda;
    text-decoration: none;
    font-weight: bold;
}

.help-section a:hover {
    text-decoration: underline;
}
  