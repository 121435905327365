.name {
    font-size: 36px;
    font-weight:900;
    color: var(--text-black);
}

.profile {
	display: flex;
}

.profileInfo {
    font-size: 18px;
	padding: 25px;
    color: var(--text-grey);
}

.profilePic {
	width:	180px;
	height: 180px;
	padding: 5px;
	margin-top: -50px;
	margin-left: 0px;
	border-radius: 100px;
	background-color: var(--primary-color);
}