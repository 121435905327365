.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure the overlay is on top of other content */
}

.popup {
    /* Layout */
    background-color: white;
    /* padding: 20px; */
    border-radius: 8px;
    position: relative;
    width: 70%;
    box-sizing: border-box;
    /* height: 520px; */
    padding-bottom: 20px;
    flex-shrink: 0;
    min-width: 600px;

    display: flex;
    flex-direction: column;

    /* Style */
    border-radius: 10px;
    background: var(--White, #FEFEFE);
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.28);

}

.popup-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: var(--text-grey)
}

.popup-header {
    background: var(--Light-Grey, #F6F5F4);
    object-fit: contain;
    height: 30px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 20px;
}

.popup-body {
    padding: 20px;
    overflow-y: scroll;

}

.popup-title {
    color:  var(--text-black);
    font-family: "Avenir Next";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin: 0;
}

.popup-description {
    margin: 10px;
}

.popup-options-container {
    display: flex;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    justify-content: center;

}

.popup-option {
    width: 375px;
    height: 100px;
    flex-shrink: 0;
    padding: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    border-radius: 10px;
    background: var(--Light-Grey, #F6F5F4); 
}

.popup-option:hover {
    background: var(--primary-lighter-color);
}

.popup-icon-container {
    display: flex;
    width: 72px;
    height: 72px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: var(--secondary-color);
    border-radius: 5px;
}

.popup-option-icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    flex-shrink: 0;
}

.popup-option-title {
    color: var(--text-black);
    text-align: left;
    font-family: "Avenir Next";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    margin-top: 13px;
}

.popup-option-title-container {
    display: flex;
    flex-direction: column;
    /* justify-content: last baseline; */
    justify-content: center;
}

.popup-tag {
    text-align: left;
    width: 250px;
    margin-top: 3px;
    padding: 5px 5px;

    /* Tag */
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.template {
        color: var(--text-grey);
    }

    &.import {
        color: var(--Dark-Purple, #6A49DB);
    }
    
}