.signin-container {
    background: var(--White, #FEFEFE);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    
    /* Styling for cauldron*/
    padding-bottom: 70px;
}

.signin-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signin-logo {
    width: 300px;
    height: 150px;
}

.signin-title {
    color:  var(--text-black);
    font-family: "Avenir Next";
    font-size: 24px;
    font-style: normal;
    font-weight: 650;
    line-height: normal;
    
    margin: 0;
}

.signin-description {
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0;
}

.signin-oauth-option {
    display: flex;
    width: 300px;
    padding: 0px 20px;
    align-items: center;    
    gap: 10px;
    justify-content: center;

    border-radius: 5px;
    background: var(--primary-color);

    &:hover {
        background: var(--primary-heavy-color);
    }
}

.signin-oauth-logo {
    width: 25px;
    height: 25px;;
}

.signin-oauth-text {
    color: var(--White, #FEFEFE);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* Email Sign In Option */
.signin-emailSignIn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.signin-emailSignIn-input {
    display: flex;
    width: 280px;
    height: 30px;
    padding: 10px 10px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);

    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    ::placeholder {
        color: var(--text-grey)
    }

    &.half {
        width: 125px;
    }


}

.signin-name-container {
    display: flex;
    gap: 10px;
}

.signin-goToSignup-link {
    color: var(--primary-color);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;

    padding: 5px;

    &:hover {
        color: var(--primary-heavy-color);
    }
}

.signin-errorMsg {
    color: maroon;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin: 0;
}

.signin-footer {
    position: fixed;
    bottom: 0;
    width: 50%;
    aspect-ratio: 4.5;
    max-width: 800px;
    pointer-events: none;

    @media (max-width: 800px) {
        display: none;
    }
}