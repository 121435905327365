.differentiationGroups-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.differentiationGroups-header {
    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 0;
}

.differentiationGroups-title-icon {
    width: 18px;
    height: 18px;
}

.differentiationGroups-description {
    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0;
}

.differentiationGroups-groups {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
}

.differentiationGroups-group {
    display: flex;
    flex-basis: 100%;
    padding: 35px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    position: relative;

    border-radius: 10px;
    border: 1px solid var(--mid-light-grey);
    background: #FFF;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
    /* border-top: 10px solid linear-gradient(90deg, #6A49DB 0%, #A546D2 100%); */
}

.differentiationGroups-group::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(90deg, #6A49DB 0%, #A546D2 100%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.differentiationGroups-group-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.differentiationGroups-group-header {
    display: flex;
    justify-content: space-between;
    width: 100%
}

.differentiationGroups-group-title {
    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin: 0;
    align-self: center;
}

.differentiationGroups-group-removeIcon {
    padding: 0;
    width: 30px;
    height: 30px;
}

.differentiationGroups-group-lineSeperator {
    width: 100%;
    height: 1px;
    margin-top: 5px;

    background: var(--primary-color);
}

.differentiationGroups-group-label {
    color: var(--text-black);
    display: flex;
    gap: 2px;
    width: 100%;

    /* Paragraph 1 */
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.differentiationGroups-group-selector {
    display: flex;
    height: 42px;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);

    /* Text */
    color: var(--text-black);

    /* Paragraph 1 */
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.differentiationGroups-accommodations-option {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.differentiationGroups-accommodations-option-label {
    color: var(--text-black);

    /* Paragraph 1 */
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.differentiationGroups-group-textInput {
    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);

    display: flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    /* Paragraph 1 */
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.differentiationGroups-addGroup-button {
    display: flex;
    width: 200px;
    padding: 10px 10px;
    align-items: center;
    justify-content: center;
    gap: 5px;

    border-radius: 5px;
    background-color: var(--primary-color);

    /* Text Styling */
    color: white;
    /* Paragraph 1 */
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
        background: var(--primary-heavy-color);
    }
}

.differentiationGroups-addGroup-icon {
    padding: 0;
    width: 20px;
    height: 20px;
}

.input-invalid {
    border: 1px solid red;
}