.alignToStandards-dropdown-row {
    display: flex;
    width: 100%;
    gap: 30px;
}

.alignToStandards-fieldLabel {
    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* Options Container */
.alignToStandards-options-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    /* align-content: top; */
    gap: 10px;
    align-self: stretch;

    max-height: 25vh;
    overflow-y: scroll;

    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);
}

.alignToStandards-option-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.alignToStandards-option-title {
    min-width: 220px;

    color: var(--primary-color);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.alignToStandards-option-desc {
    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.alignToStandards-option-check {
    margin-left: auto;

    &:checked {
        background-color: var(--primary-color);
    }
}

.alignToStandard-line-seperator {
    flex: 0 0 auto;

    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 1px;
    background: var(--mid-light-grey);
}

/* Selected Standards */
.alignToStandards-selectedStandards-container {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    /* max-width: 100%; */
    gap: 5px;
}

.alignToStandards-selectedStanard-option {
    background-color: var(--primary-muted-color);
    border-radius: 15px;
    display: flex;
    padding: 0px 5px;
}

.alignToStandards-selectedStanard-text {
    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin: 4px;
}


/* Arrow Styling */
.alignToStandards-arrow-toggle-container {
    cursor: pointer;
    display: inline-block;
    padding: 0;
    margin: 0 10px;
}

.alignToStandards-arrow {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;

    &.up {
        transform: rotate(180deg);
    }

    &.down {
        transform: rotate(0deg);
    }
}

/* Switch Styling */
/* .alignToStandards-switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.alignToStandards-toggle-switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin: 0;
}

.alignToStandards-toggle-switch input {
    opacity: 0;
    width: 34px;
    height: 20px;
    cursor: pointer;
}

.alignToStandards-toggle-switch input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

.alignToStandards-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    pointer-events: none;
    border-radius: 20%;
    border-radius: 34px;

}

.alignToStandards-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;

}

input:checked + .alignToStandards-slider {
    background-color: var(--primary-color);
}

input:checked + .alignToStandards-slider:before {
    transform: translateX(14px);
} */