.dragAndDrop-upload-input-container {
    display: flex;
    width: 100%;
    height: 200px;
    /* padding: 0px 74px 0px 75px; */
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 20px;
    border: 2px dashed #BABABA;
    background: #F9F9F9;

    &.input-invalid {
        border: 2px dashed red;
    }
}

.dragAndDrop-upload-input-info {
    width: 600px;
    height: 93px;
    flex-shrink: 0;
}

.dragAndDrop-upload-input-heading {
    color: var(--text-black);
    text-align: center;
    font-family: "Avenir Next";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-bottom: 10px;
}

.dragAndDrop-upload-input-subSection{
    width: 600px;
    height: 37px;
    flex-shrink: 0;
    display: flex;
    gap: 20px;
    justify-content: center;
}

.dragAndDrop-upload-input-subText {
    color: var(--text-grey);
    text-align: center;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: center;
    margin: 0;
}

.dragAndDrop-upload-input-inputElement {
    flex-shrink: 0;
    padding: 0px 10px;

    border-radius: 20px;
    background: var(--primary-color);
    border: none;
    /* padding: 5px; */

    &:hover {
        background: var(--primary-heavy-color);
    }

    color: #FFF;
    text-align: center;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.dragAndDrop-upload-preview-container {
    border-radius: 10px;
    background: var(--light-grey);

    display: flex;
    width: 100%;
    padding: 30px;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
}

.dragAndDrop-upload-preview-icon {
    width: 45px;
    height: 45px;
}

.dragAndDrop-upload-preview-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    gap: 2px;
    flex: 1 0 0;
}

.dragAndDrop-upload-preview-text {
    color: var(--text-black);

    margin: 0;

    /* Paragraph 1 */
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    line-height: normal;

    &.name {
        font-weight: 700;
    }

    &.size {
        font-weight: 400;
    }    
}

.dragAndDrop-upload-preview-img {
    width: 40px;
    height: 40px;

    padding: 0;
}
