
.previewEditWorksheet-container {
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--mid-light-grey);
    background: var(--background-white);
    padding: 20px;
}

/* Header Styling */
.previewEditWorksheet-header {
    padding-top: 10px;
    margin-right: 40px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.previewEditWorksheet-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 10px;
    background: linear-gradient(90deg, #6A49DB 0%, #A546D2 100%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.previewEditWorksheet-banner-header-rightItems {
    display: flex;
    align-items: center;
}

.previewEditWorksheet-finish-button {
    display: flex;
    padding: 10px 30px;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;

    border-radius: 5px;
    border: 1px solid var(--Light-Purple, #8C52FF);
    background: linear-gradient(90deg, #8C52FF 28.5%, #A546D2 93.5%);

    /* Text Styling */
    color: var(--White, #FEFEFE);
    text-align: center;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
        /* background-color: var(--primary-heavy-color); */
        padding: 11px 31px;
    }
    
}

.previewEditWorksheet-finish-icon {
    width: 20px;
    aspect-ratio: 1;
}

.previewEditWorksheet-header-leftItems {
    display:flex;
    flex-direction: column;
    gap: 10px;
}


.previewEditWorksheet-header-rightItems {
    display:flex;
    flex-direction: row;
    gap: 10px;
}

.previewEditWorksheet-title {
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin: 0;
}

.previewEditWorksheet-subTitle {
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0;
}

.previewEditWorksheet-changeWorksheet-button {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    height: 50px;

    border-radius: 5px;
    background: var(--primary-color);

    /* Text Styling */
    color: white;
    text-align: center;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
        background: var(--primary-heavy-color);
    }
}

/* Content Styling */
.previewEditWorksheet-content {
    display: flex;
    /* justify-content: center; */
    height: 100vh;
    overflow-x: scroll;
}


.previewEditWorksheet-preview {
    width: 100%;
    height: 70vh;
}

.previewEditWorksheet-edit-title {
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin: 0;
}

.previewEditWorksheet-edit-subTitle {
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0;
}