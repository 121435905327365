.banner {
	/* The image used */
	background-image: url("../../../assets/TeacherProfile/banner.jfif");

	min-height: 250px;
	min-width: 100%;	
	background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
	border-radius: 8px;
}