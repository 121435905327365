.fade-enter {
    opacity: 0;
    transform: translateY(-20px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 500ms, transform 500ms;
}

.editWorksheet-container {
    padding: 0px 0px 20px 0px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    /* height: 84.5vh; */
    /* aspect-ratio: 2.05/3; */
    /* overflow-y: scroll; */
}

.editWorksheet-questions-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 20px;

    /* aspect-ratio: 2/3; */
    overflow-y: scroll;
    overflow-x: hidden;
}

.editWorksheet-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 10px;
    /* background-color: var(--background-white-lighter); */
}

.editWorksheet-addDropDown-active {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap; /* Prevents wrapping of items */
    padding-left: 10px;
    padding-right: 10px;


}

.editWorksheet-addDropDown-inactive {
    display: none;
}

.editWorksheet-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    height: 40px;

    border-radius: 5px;
    background: var(--primary-color);

    /* Text Styling */
    color: white;
    text-align: center;
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
        background: var(--primary-heavy-color);
    }
}

.editWorksheet-button.active {
    background-color: var(--primary-heavy-color);
}

.editWorksheet-button + .editWorksheet-button {
    margin-left: 5px;
}

.editWorksheet-title {
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin: 0;
}

.editWorksheet-subTitle {
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0;
}

.editWorksheet-question-container {
    width:100%;
    display: flex;
    padding: 20px 10px;
    flex-direction: column;   
    padding-top: 5px;
}

.editWorksheet-listItem-container {
    display: flex;
    flex-direction: row;
    /* background: var(--background-white-lighter); */
    /* border-radius: 0px 10px 10px 0px; */
    overflow: hidden;

    border-radius: 10px;
    border: 1px solid var(--mid-light-grey);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}

.editWorksheet-listItem-spaceAdder {
    /* justify-content: right; */
    padding-top: 10px;
    display: flex;
    gap: 10px;
}

.editWorksheet-listItem-spaceAdder-button {
    background-color: var(--primary-color); /* Green background */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 10%; /* Fully rounded corners */
    width: 30px; /* Width to make it a square */
    height: 30px; /* Height to match width */
    display: inline-flex; /* Align content in the center */
    justify-content: center;
    align-items: center;
    font-size: 16px; /* Adjust text size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition */

    &:hover {
        background-color: var(--primary-heavy-color); /* Slightly darker green on hover */
    }
}

.editWorksheet-listItem-handle {
    width: 40px;
    overflow: clip;
    background-color: var(--secondary-lighter-color);
    color: white;
    text-align: center;
    vertical-align: middle;
    cursor: grab;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px 0px 0px 10px;

    &:active {
        background: var(--secondary-color);
        cursor: grabbing;
    }
}

.editWorksheet-listItem-handle-disabled {
    width: 40px;
    overflow: clip;
    background-color: var(--mid-light-grey);
    color: white;
    text-align: center;
    vertical-align: middle;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px 0px 0px 10px;
}

.editWorksheet-listItem-handle-remove {
    width: 40px;
    overflow: clip;
    padding: 0px;
    border-radius: 0px;
    background-color: tomato;

    border-radius: 10px 0px 0px 10px;

    &:hover {
        background: red;
    }
}


.editWorksheet-question-label {
    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* background: var(--background-white-lighter); */
    border-radius: 4px;

    margin: 0;
    padding-top: 5px;

    &.question {
        font-size: 16px;
        font-weight: 600;
    }
}

.editWorksheet-question-textArea{
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    padding: 10px 10px;
    resize: none;

    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);

    /* Text Styling */
    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.editWorksheet-textArea-bg {
    /* background: var(--background-white-lighter); */
    border-radius: 4px;
    padding-bottom: 5px;
}

.editWorksheet-question-textInput {
    border-radius: 5px;
    border: 1px solid var(--mid-light-grey);

    display: flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    /* Paragraph 1 */
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.editWorksheet-question-wrongAns-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}

.editWorksheet-glossary-item-row {
    display: flex;
}

ul {
    padding: 0;
    list-style-type: none;
}
  