.underConstruction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100vh;
}

.underConstruction-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.underConstruction-img {
    align-self: center;
    width: 500px;
}

.underConstruction-text {
    margin-top: 20px;
    text-align: center;
}