/* This is the styling file for the NavBar component */
.nav-container {
	width: 120px;
	background-color: var(--primary-color);
	display: flex;
	padding: 30px 35px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	align-self: stretch;

	position: relative; /* for minimize icon */

	&.minimized {
		width: 20px;
		padding: 35px 20px;
	}
}

/* Styling for Nav Logo*/
.nav-logo {
	width: 200px;
	height: 100px;

	&.minimized {
		width: 50px;
		height: 50px;
		margin-bottom: 20px;
	}
}

/* Styling for Nav Items*/
.nav-items {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	align-self: stretch;
}

.nav-item {
	display: flex;
	height: 47px;
	align-items: center;
	gap: 12px;
	align-self: stretch;
}

.nav-item-clickable {
	display: flex;
	align-items: center;
	gap: 10px;
}

.nav-item-icon {
	width: 20px;
	height: 20px;
}

.nav-item-text {
	width: 105px;
	color: white;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-align: left;
}

.nav-item-active {
	width: 8px;
	flex-shrink: 0;
	align-self: stretch;

	border-radius: 10px 0px 0px 10px;
	background: var(--White, #FEFEFE);

	position: relative;
	right: 0;
}
/* End styling for Nav Items*/

/* Styling for Nav Footer*/
.nav-footer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	flex: 1 0 0;
	align-self: stretch;
	position: relative;
}

.nav-footer-item {
	display: flex;
	height: 47px;
	align-items: center;
	gap: 12px;
	align-self: stretch;
}

.nav-footer-clickable {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 0;
}

.nav-footer-minimize {
	z-index: 100;
    object-position: right;
    width: 25px;
	height: 25px;

    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    
    margin-right: 20px;
	margin-bottom: 20px;
    text-align: center;
    white-space: nowrap;
}

.nav-footer-minimize-icon {
	width: 25px;
	height: 25px;
	transform: rotate(180deg);

	&.flip {
        transform: rotate(0deg);
    }
}
/* End styling for Nav Footer*/
