.expandedContainer {
    display: flex;
    padding: 15px; 
    text-align: center;
    background-color: var(--light-grey);
    border-radius: 8px;
}

.achieveIcon {
    width: 100px;
    height: 100px;
}

.achieveInfo { 
    width: 100%; 
    height: 100%;
    margin-left: 15px
}

.achieveTitle {
    margin-bottom: 25px;
    font-size: 24px;
}

.achieveCondition {
   text-align: left; 
}

.achieveProgressBar {
    height: 20px;
    border-radius: 12px;
    margin-bottom: 12px;
    box-shadow: 0px 1px;
    justify-content: center;
}