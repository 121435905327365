@font-face {
    font-family: 'BubblegumSans';
    src: local('BubblegumSans'), url('./WorksheetFonts/BubblegumSans-Regular.ttf') format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'NotoSans';
    src: local('NotoSans'), url('./WorksheetFonts/NotoSans-Regular.ttf') format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}