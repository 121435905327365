.yourActivitiesDashboardHeader-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.yourActivitiesDashboardHeader-rightItems {
    display: flex;
    gap: 10px;
    align-items: self-end;
}

.yourActivitiesDashboardHeader-heading {
    color: var(--text-black);

    /* Heading 1 */
    font-family: "Avenir Next";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 335px;
    margin: 0;
}

.yourActivitiesDashboardHeader-filter {
    width: 32px;
    height: 32px;
}

.yourActivitiesDashboardHeader-filterButton {
    padding: 0;
}

.yourActivitiesDashboardHeader-searchBar {
    /* Layout */
    display: flex;
	gap: 20px;
	font-size: 14px;
	color: var(--text-grey);
	font-weight: 400;
	padding: 9px 20px;

    /* Style */
    border-radius: 100px;
    background: var(--light-grey);
}

.yourActivitiesDashboardHeader-searchBar-input {
    font-family:
		Avenir Next,
		sans-serif;
	flex-grow: 1;
	flex-basis: auto;
	margin: auto 0;
	border: none;
	background-color: transparent;
	font-size: inherit;
	color: inherit;

	&::placeholder {
		color: inherit;
	}

	&:focus {
		outline: none;
	}
}

.yourActivitiesDashboardHeader-searchBar-icon {
    width: 24px;
    height: 24px;
	object-fit: contain;
}