.yourActivityCard-container {
    display: flex;
    width: 250px;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    cursor: pointer;
}

.yourActivityCard-thumbnail {
    height: 180px;
    width: 250px;
    align-self: stretch;
    overflow: hidden;
    position: relative;

    border-radius: 5px;
    background: var(--Light-Grey, #F6F5F4);

    grid-template: 1fr / 1fr;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.2);

}

.yourActivityCard-mini {
    position: absolute;
    color: black;
    display: inline-block;
    line-height: 30%;
    zoom: 0.3;
    -ms-zoom: 0.3;
    -webkit-zoom: 0.3;
    -moz-transform: scale(0.3, 0.3);
    -moz-transform-origin: left center;
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.2);
}

.yourActivityCard-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.yourActivityCard-description {
    display: flex;
    width: 225px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
}

.yourActivityCard-title {
    margin: 0;

    color: var(--text-black);
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.yourActivityCard-lastEdited {
    margin: 0;

    color: var(--text-grey);
    font-family: "Avenir Next";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.yourActivityCard-action {
    padding: 0;
}