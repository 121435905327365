/* Feedback.css */
.feedback-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.feedback-form textarea {
    resize: vertical;
    font-size: 12px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    margin-bottom: 12px;
    min-height: 200px;
    transition: border-color 0.3s ease;
}

.feedback-form textarea:focus {
    border-color: var(--primary-color);
}

.feedback-form button {
    background-color: var(--primary-color);
    color: white;
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.feedback-form button:hover {
    background-color: var(--primary-lighter-color);
}

.feedback-form button:active {
    background-color: var(--primary-heavy-color);
}

.feedback-form button:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}
